import React from 'react';
import Banner from '../Banner Section/banner';
import Main from '../Parallax/parallax';

export default function Homepage(): JSX.Element {
    return (
        <main id='homepage'>
            <Banner />
            <Main />
        </main>
    );
}
