import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation, getLanguage } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as BackButton } from './back.svg';
import { RootState } from '../../Redux/store';
import './articles.less';

export default function Article(): JSX.Element {
    const t = useTranslation();
    const { title } = useParams<{ title: string }>();
    const history = useHistory();
    const locale = getLanguage();
    const { data } = useSelector((state: RootState) => state.fetchNews);
    const article = Object.values(data || {}).find(
        eachNews => eachNews.title === title
    );

    const parseTimestamp = (timestamp: number): string => {
        const fullDate = new Date(timestamp);
        const date = fullDate.getDate();
        const month = t(`Main.Media Coverage.${fullDate.getMonth()}`);
        const year = fullDate.getFullYear();
        return locale === 'zh'
            ? `${year}年${fullDate.getMonth() + 1}月${date}日`
            : `${date} ${month}, ${year}`;
    };

    useEffect(() => {
        const navigationHandler = (evt: MouseEvent): void => {
            const target = evt.target as HTMLElement | null;
            const targetLink = target?.closest('a')?.getAttribute('href');
            if (!targetLink) return;
            evt.preventDefault();
            history.push(targetLink);
        };
        const anchors = document.querySelectorAll(
            '#article #right a'
        ) as NodeListOf<HTMLAnchorElement>;
        anchors.forEach(a => a.addEventListener('click', navigationHandler));
        return (): void =>
            anchors.forEach(a =>
                a.removeEventListener('click', navigationHandler)
            );
    }, []);

    // eslint-disable-next-line no-nested-ternary
    return !data ? (
        <div />
    ) : article ? (
        <main id='article' data-article={title}>
            <img src={article.img} alt='article cover' />
            <Link to='/news'>
                <BackButton /> {t('Main.Media Coverage.All News')}
            </Link>
            <hr />
            <div id='content-wrapper'>
                <time>{parseTimestamp(article.timestamp)}</time>
                <div id='content'>
                    <h1>{article.title}</h1>
                    {ReactHtmlParser(article.content)}
                </div>
            </div>
        </main>
    ) : (
        <Redirect to='/news' />
    );
}
