import React, { useState, useEffect } from 'react';
import { setLanguage, useTranslation } from 'react-multi-lang';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from './Logo.svg';
import './header.less';

export default function Header(): JSX.Element {
    const t = useTranslation();
    const [open, setOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => setOpen(false));
        return () => unlisten();
    }, []);

    document.body.style.overflow = open ? 'hidden' : 'auto';
    return (
        <>
            <header>
                <div className={`wrapper ${open ? 'open' : ''}`}>
                    <nav id='home'>
                        <Link to='/'>
                            <Logo />
                        </Link>
                        <Link to='/portfolio'>{t('Header.Portfolio')}</Link>
                        <Link to='/news'>{t('Header.Media Coverage')}</Link>
                        <Link to='/contact'>{t('Header.Contact Us')}</Link>
                        <span>
                            <button
                                type='button'
                                onClick={() => {
                                    setLanguage('zh');
                                    setOpen(false);
                                }}
                            >
                                中
                            </button>
                            |
                            <button
                                type='button'
                                onClick={() => {
                                    setLanguage('en');
                                    setOpen(false);
                                }}
                            >
                                EN
                            </button>
                        </span>
                    </nav>
                    <button type='button' onClick={() => setOpen(!open)}>
                        {open ? '✕' : <FontAwesomeIcon icon={faBars} />}
                    </button>
                </div>
            </header>
            <div id='header-placeholder' />
        </>
    );
}
