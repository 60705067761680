import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faGripLines,
    faHourglassHalf,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import './portfolio.less';
import Reorder from 'react-reorder';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RootState } from '../../../Redux/store';
import { Content, Portfolio } from '../../../Redux/fetchPortfolio';

export default function PortfolioEdit(): JSX.Element {
    const { data } = useSelector((state: RootState) => state.fetchPortfolio);
    const [list, setList] = useState<undefined | [string, Content][]>();
    const [disabledReorder, setDisabled] = useState(true);
    const [editTarget, setEditTarget] = useState('');
    const [uploading, setUploading] = useState(false);
    const [editData, setEditData] = useState<Content>({
        order: -1,
        title: {
            en: '',
            zh: '',
        },
        content: {
            en: '',
            zh: '',
        },
        img: '',
        url: '',
    });
    const [edit2Data, setEdit2Data] = useState<
        Omit<Portfolio, 'private-equity'>
    >();

    useEffect(() => {
        setList(Object.entries(data?.['private-equity'] || {}));
    }, [data]);
    list?.sort(([, a], [, b]) => a.order - b.order);

    const changeOrder = (previousIndex: number, newIndex: number): void => {
        if (!list) return;
        const newList = Reorder.reorder(list, previousIndex, newIndex) as [
            string,
            Content
        ][];
        newList.forEach(([key, item], i) => {
            // eslint-disable-next-line no-param-reassign
            item.order = i;
            firebase
                .database()
                .ref(`/portfolio/private-equity/${key}/order`)
                .set(i);
        });
        setList([...newList]);
    };

    return editTarget ? (
        <main id='portfolio-editor' className='editor'>
            <h1>Portfolio</h1>
            {editTarget === 'real-estate-absolute-return' ? (
                <>
                    {(['section2', 'section3'] as ['section2', 'section3']).map(
                        section => (
                            <>
                                <h2>
                                    {section === 'section2'
                                        ? 'Real Estate'
                                        : 'Absolute Returns'}
                                </h2>
                                {(['en', 'zh'] as ['en', 'zh']).map(locale => (
                                    <>
                                        <h3>{locale}</h3>
                                        <CKEditor
                                            key={`${section}${locale}`}
                                            editor={ClassicEditor}
                                            data={
                                                edit2Data?.[section].content[
                                                    locale
                                                ]
                                            }
                                            config={{
                                                toolbar: [
                                                    'undo',
                                                    'redo',
                                                    '|',
                                                    'bold',
                                                    'italic',
                                                    'numberedList',
                                                    'bulletedList',
                                                    '|',
                                                    'link',
                                                ],
                                            }}
                                            onChange={(
                                                _: unknown,
                                                editor: {
                                                    getData: () => string;
                                                }
                                            ): void => {
                                                const newData = {
                                                    ...edit2Data,
                                                } as Omit<
                                                    Portfolio,
                                                    'private-equity'
                                                >;

                                                newData[section].content[
                                                    locale
                                                ] = editor.getData();

                                                setEdit2Data(newData);
                                            }}
                                        />
                                    </>
                                ))}
                            </>
                        )
                    )}
                    <button
                        disabled={uploading}
                        type='button'
                        aria-label='save'
                        onClick={async () => {
                            setUploading(true);
                            const database = firebase.database();
                            const ref = database.ref('/portfolio');
                            await Promise.all([
                                ref.child('section2').set(edit2Data?.section2),
                                ref.child('section3').set(edit2Data?.section3),
                            ]);
                            setUploading(false);
                            setEditTarget('');
                        }}
                    >
                        {uploading ? (
                            <>
                                Saving...{' '}
                                <FontAwesomeIcon icon={faHourglassHalf} />
                            </>
                        ) : (
                            <>
                                Save <FontAwesomeIcon icon={faCheck} />
                            </>
                        )}
                    </button>

                    <button
                        type='button'
                        aria-label='return'
                        onClick={() => {
                            setEdit2Data(undefined);
                            setEditTarget('');
                        }}
                    >
                        Go Back <FontAwesomeIcon icon={faUndo} />
                    </button>
                </>
            ) : (
                <>
                    <label htmlFor='name-en'>
                        Name
                        <input
                            onChange={evt => {
                                editData.title.en = evt.target.value;
                                setEditData({ ...editData });
                            }}
                            id='name-en'
                            type='textbox'
                            defaultValue={editData.title.en}
                        />
                    </label>
                    <label htmlFor='name-zh'>
                        名稱
                        <input
                            onChange={evt => {
                                editData.title.zh = evt.target.value;
                                setEditData({ ...editData });
                            }}
                            id='name-zh'
                            type='textbox'
                            defaultValue={editData.title.zh}
                        />
                    </label>
                    <label htmlFor='url'>
                        Link
                        <input
                            onChange={evt => {
                                editData.url = evt.target.value;
                                setEditData({ ...editData });
                            }}
                            id='url'
                            type='textbox'
                            defaultValue={editData.url}
                        />
                    </label>
                    <label htmlFor='image'>
                        Image
                        {editData.img ? (
                            <figure>
                                <img
                                    src={editData.img}
                                    alt={editData.title.en}
                                />
                            </figure>
                        ) : null}
                        <input
                            type='file'
                            accept='image/*'
                            onChange={(evt): void => {
                                if (evt.target.files) {
                                    const reader = new FileReader();
                                    const file = evt.target.files[0];
                                    if (file) {
                                        reader.readAsDataURL(file);
                                        reader.onloadend = (): void => {
                                            // eslint-disable-next-line no-param-reassign
                                            editData.img = reader.result as string;
                                            setEditData({ ...editData });
                                        };
                                    }
                                }
                            }}
                        />
                    </label>
                    <label htmlFor='content-en'>
                        Description
                        <textarea
                            id='content-en'
                            defaultValue={editData.content.en}
                            onChange={(evt): void => {
                                editData.content.en = evt.target.value;
                                setEditData({ ...editData });
                            }}
                        />
                    </label>
                    <label htmlFor='content-zh'>
                        介紹
                        <textarea
                            id='content-zh'
                            defaultValue={editData.content.zh}
                            onChange={(evt): void => {
                                editData.content.zh = evt.target.value;
                                setEditData({ ...editData });
                            }}
                        />
                    </label>
                    <button
                        type='button'
                        aria-label='return'
                        onClick={() => {
                            setEditData({
                                order: -1,
                                title: {
                                    en: '',
                                    zh: '',
                                },
                                content: {
                                    en: '',
                                    zh: '',
                                },
                                img: '',
                                url: '',
                            });
                            setEditTarget('');
                        }}
                    >
                        Go Back <FontAwesomeIcon icon={faUndo} />
                    </button>
                    <button
                        disabled={uploading}
                        type='button'
                        aria-label='save'
                        onClick={async () => {
                            setUploading(true);
                            const newData = { ...editData };
                            let imageString = '';
                            if (
                                newData.img &&
                                /^data:image\/([a-zA-Z]*);base64,/.test(
                                    newData.img
                                )
                            ) {
                                imageString = newData.img;
                                newData.img = '';
                            }
                            const database = firebase.database();
                            const storage = firebase.storage();
                            const ref =
                                editTarget === 'temp'
                                    ? database
                                          .ref('/portfolio/private-equity')
                                          .push()
                                    : database
                                          .ref('/portfolio/private-equity')
                                          .child(editTarget);
                            await ref.set(newData);
                            if (list && editTarget === 'temp') {
                                list.push([ref.key as string, newData]);
                                changeOrder(list.length - 1, 0);
                            }
                            if (imageString) {
                                const storageRef = storage
                                    .ref('Portfolio/')
                                    .child(ref.key as string);
                                await storageRef.putString(
                                    imageString,
                                    'data_url'
                                );
                                const imageUrl = await storageRef.getDownloadURL();
                                await ref.child('img').set(imageUrl);
                            }
                            setUploading(false);
                            setEditData({
                                order: -1,
                                title: {
                                    en: '',
                                    zh: '',
                                },
                                content: {
                                    en: '',
                                    zh: '',
                                },
                                img: '',
                                url: '',
                            });
                            setEditTarget('');
                        }}
                    >
                        {uploading ? (
                            <>
                                Saving...{' '}
                                <FontAwesomeIcon icon={faHourglassHalf} />
                            </>
                        ) : (
                            <>
                                Save <FontAwesomeIcon icon={faCheck} />
                            </>
                        )}
                    </button>
                </>
            )}
        </main>
    ) : (
        <main id='portfolio'>
            <div id='header'>
                <h1>Portfolio</h1>
            </div>
            <div className='wrapper'>
                <button
                    type='button'
                    className='new'
                    onClick={() => setEditTarget('temp')}
                >
                    New
                </button>
                <button
                    type='button'
                    className='reorder'
                    onClick={() => setDisabled(!disabledReorder)}
                >
                    {disabledReorder ? 'Reorder' : 'Done'}
                </button>
                <table>
                    <thead>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        {disabledReorder ? null : <th />}
                        <th>Action</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Url</th>
                    </thead>
                    <Reorder
                        reorderId='my-list'
                        component='tbody'
                        lock='horizontal'
                        onReorder={(
                            _: never,
                            previousIndex: number,
                            newIndex: number
                        ) => {
                            changeOrder(previousIndex, newIndex);
                        }}
                        disabled={disabledReorder}
                    >
                        {(list || []).map(([key, company]) => (
                            <tr key={key}>
                                {disabledReorder ? null : (
                                    <td className='handle-col'>
                                        <div className='handle'>
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                            />
                                        </div>
                                    </td>
                                )}
                                <td>
                                    <button
                                        type='button'
                                        className='edit'
                                        onClick={() => {
                                            setEditTarget(key);
                                            setEditData(company);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type='button'
                                        className='delete'
                                        onClick={async () => {
                                            await firebase
                                                .database()
                                                .ref(
                                                    '/portfolio/private-equity'
                                                )
                                                .child(key)
                                                .set(null);
                                            await firebase
                                                .storage()
                                                .ref('Portfolio/')
                                                .child(key)
                                                .delete();
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                                <td className='img-col'>
                                    <img
                                        src={company.img}
                                        alt={company.title.en}
                                    />
                                </td>
                                <td>
                                    {company.title.en}
                                    <br />
                                    {company.title.zh}
                                </td>
                                <td className='content-col'>
                                    {company.content.en}
                                    <br />
                                    {company.content.zh}
                                </td>
                                <td>
                                    <a href={company.url}>{company.url}</a>
                                </td>
                            </tr>
                        ))}
                    </Reorder>
                </table>
                <table>
                    <thead>
                        <th>Action</th>
                        <th>Title</th>
                        <th>Content</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan={2}>
                                <button
                                    type='button'
                                    className='edit'
                                    onClick={() => {
                                        if (data) {
                                            setEditTarget(
                                                'real-estate-absolute-return'
                                            );
                                            setEdit2Data({
                                                section2: data.section2,
                                                section3: data.section3,
                                            });
                                        }
                                    }}
                                >
                                    Edit
                                </button>
                            </td>
                            <td>
                                {data?.section2.title.en}
                                <br />
                                {data?.section2.title.zh}
                            </td>
                            <td
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: `${data?.section2.content.en}<br />${data?.section2.content.zh}`,
                                }}
                            />
                        </tr>
                        <tr>
                            <td>
                                {data?.section3.title.en}
                                <br />
                                {data?.section3.title.zh}
                            </td>
                            <td
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: `${data?.section3.content.en}<br />${data?.section3.content.zh}`,
                                }}
                            />
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    );
}
