import { SuccessAction, FailureAction, FetchState, Locale } from './base-types';

const SUCCESS = 'FETCH_HOME_SUCCESS';
const FAIL = 'FETCH_HOME_FAIL';

export interface BlockContent {
    title: Locale;
    content: Locale;
}

export interface Home {
    introduction: Locale;
    background: Locale;
    approach: {
        1: BlockContent;
        2: BlockContent;
        3: BlockContent;
    };
    investment: {
        1: BlockContent;
        2: BlockContent;
        3: BlockContent;
        4: BlockContent;
    };
}

type Action = SuccessAction<typeof SUCCESS, Home> | FailureAction<typeof FAIL>;

const initialState: FetchState<Home> = {
    data: undefined,
    error: undefined,
};

export default function reducer(
    state = initialState,
    action: Action
): FetchState<Home> {
    switch (action.type) {
        case SUCCESS:
            return {
                data: action.payload,
                error: undefined,
            };
        case FAIL:
            return {
                data: undefined,
                error: action.payload,
            };
        default:
            return state;
    }
}
