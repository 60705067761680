import React, { useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import './contact.less';

export default function Contact(): JSX.Element {
    const t = useTranslation();
    const [location, setLocation] = useState<'kowloon' | 'hong kong'>(
        'kowloon'
    );
    const address = {
        kowloon: {
            lat: 22.2988607,
            lon: 114.1737588,
            query: '其士大廈',
        },
        'hong kong': {
            lat: 22.2875197,
            lon: 114.1475747,
            query: '成利源大廈',
        },
    };
    return (
        <main id='contact'>
            <h1>{t('Main.Contact Us.Contact Us')}</h1>
            <div className='wrapper'>
                <div id='left'>
                    {/* eslint-disable-next-line */}
                    <address onMouseEnter={() => setLocation('kowloon')} onClick={() => setLocation('kowloon')}>
                        {t('Main.Contact Us.Address.Kowloon')}
                    </address>
                    {/* eslint-disable-next-line */}
                    <address onMouseEnter={() => setLocation('hong kong')} onClick={() => setLocation('hong kong')}>
                        {t('Main.Contact Us.Address.Hong Kong')}
                    </address>
                    <span>
                        {t('Main.Contact Us.Tel')}:{' '}
                        <a href='tel:+852-2123-4567'>+852 2123 4567</a>
                    </span>
                    <span>
                        {t('Main.Contact Us.Email')}:{' '}
                        <a href='info@capitaltimex.com'>
                            info@capitaltimex.com
                        </a>
                    </span>
                </div>
                <div id='right'>
                    <iframe
                        title='Google Maps'
                        width='100%'
                        height='100%'
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${address[location].query}&center=${address[location].lat},${address[location].lon}&zoom=17`}
                    />
                </div>
            </div>
        </main>
    );
}
