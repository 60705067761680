import React from 'react';
import { useTranslation, getLanguage } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../Redux/store';
import './news.less';

export default function News(): JSX.Element {
    const t = useTranslation();
    const locale = getLanguage();
    const { data } = useSelector((state: RootState) => state.fetchNews);
    const parseTimestamp = (timestamp: number): string => {
        const fullDate = new Date(timestamp);
        const date = fullDate.getDate();
        const month = t(`Main.Media Coverage.${fullDate.getMonth()}`);
        const year = fullDate.getFullYear();
        return locale === 'zh'
            ? `${year}年${fullDate.getMonth() + 1}月${date}日`
            : `${date} ${month}, ${year}`;
    };

    return (
        <main id='news'>
            <section id='banner'>
                <h1>{t('Main.Media Coverage.Media Coverage')}</h1>
            </section>
            <section id='list'>
                <h2>{t('Main.Media Coverage.News')}</h2>
                <div className='wrapper'>
                    {Object.entries(data ?? {})
                        .sort(
                            ([, { timestamp: a }], [, { timestamp: b }]) =>
                                b - a
                        )
                        .map(([key, eachNews]) => (
                            <div className='row' key={key}>
                                <hr />
                                <div>
                                    <time>
                                        {parseTimestamp(eachNews.timestamp)}
                                    </time>
                                    <Link
                                        to={`/news/${encodeURI(
                                            eachNews.title
                                        )}`}
                                    >
                                        {eachNews.title}
                                    </Link>
                                </div>
                            </div>
                        ))}
                </div>
            </section>
        </main>
    );
}
