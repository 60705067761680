import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    projectId: 'capital-time-x',
    databaseURL: 'https://capital-time-x-default-rtdb.firebaseio.com/',
    authDomain: 'capital-time-x.firebaseapp.com',
    storageBucket: 'capital-time-x.appspot.com',
};
const app = firebase.apps.length
    ? firebase.app()
    : firebase.initializeApp(config);

export default app;
