import { SuccessAction, FailureAction, FetchState } from './base-types';

const SUCCESS = 'FETCH_NEWS_SUCCESS';
const FAIL = 'FETCH_NEWS_FAIL';

export interface News {
    content: string;
    img: string;
    title: string;
    timestamp: number;
}

type Action =
    | SuccessAction<typeof SUCCESS, { [key: string]: News }>
    | FailureAction<typeof FAIL>;

const initialState: FetchState<{ [key: string]: News }> = {
    data: undefined,
    error: undefined,
};

export default function reducer(
    state = initialState,
    action: Action
): FetchState<{ [key: string]: News }> {
    switch (action.type) {
        case SUCCESS:
            return {
                data: action.payload,
                error: undefined,
            };
        case FAIL:
            return {
                data: undefined,
                error: action.payload,
            };
        default:
            return state;
    }
}
