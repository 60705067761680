import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useLocation } from 'react-router';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBars, faHome } from '@fortawesome/free-solid-svg-icons';
import firebaseApp from '../../initFirebase';
import { ReactComponent as Logo } from './Logo.svg';
import { ReactComponent as NewsIcon } from './News_ICON.svg';
import { ReactComponent as PortfolioIcon } from './Profile_ICON.svg';
import { ReactComponent as LogoutIcon } from './Logout_ICON.svg';
import { ReactComponent as HomeIcon } from './Home.svg';
import './login.less';
import './dashboard-header.less';
import News from './News/news';
import Portfolio from './Portfolio/portfolio';
import Home from './Home/home';

export default function Dashboard(): JSX.Element {
    const auth = firebaseApp.auth();
    const location = useLocation();
    const [authState, setAuthState] = useState<
        null | 'waiting' | firebase.User | string
    >('waiting');
    auth.onAuthStateChanged(user => {
        if (typeof authState === 'string' && authState !== 'waiting') return;
        setAuthState(user);
    });
    const [mobileSidebarOpened, setOpen] = useState(false);
    useEffect(() => {
        const clickHandler = (evt: MouseEvent): void => {
            if (!mobileSidebarOpened) {
                return;
            }
            let element = evt.target as HTMLElement;
            let clickedOnSidebar = false;
            while (element.parentElement !== null) {
                if (element.id === 'sidebar-toggler') return;
                if (element.id === 'sidebar') {
                    clickedOnSidebar = true;
                }
                element = element.parentElement;
            }
            if (!clickedOnSidebar) {
                setOpen(false);
            }
        };
        window.addEventListener('click', clickHandler);
        return () => window.removeEventListener('click', clickHandler);
    }, [mobileSidebarOpened]);

    return !Object.prototype.hasOwnProperty.call(authState ?? {}, 'uid') ? (
        <main id='login'>
            <form
                onSubmit={async evt => {
                    evt.preventDefault();
                    const [
                        emailField,
                        passwordField,
                        rememberFeild,
                    ] = Array.from(
                        evt.target as HTMLFormElement
                    ) as HTMLInputElement[];
                    auth.setPersistence(
                        rememberFeild.checked
                            ? firebase.auth.Auth.Persistence.LOCAL
                            : firebase.auth.Auth.Persistence.SESSION
                    );
                    try {
                        setAuthState(
                            (
                                await firebase
                                    .auth()
                                    .signInWithEmailAndPassword(
                                        emailField.value,
                                        passwordField.value
                                    )
                            ).user
                        );
                    } catch (err) {
                        setAuthState((err as firebase.FirebaseError).message);
                    }
                }}
            >
                <h1>
                    <Logo />
                </h1>
                <input type='username' placeholder='Email: ' />
                <input type='password' placeholder='Password: ' />
                <input type='checkbox' />
                <FontAwesomeIcon icon={faCheck} />
                <span>Keep Me Logged In</span>
                <button type='submit' disabled={authState === 'waiting'}>
                    Login
                </button>
                <Link to='/'>Back to my web</Link>
                {typeof authState === 'string' && authState !== 'waiting' ? (
                    <div id='error'>{authState}</div>
                ) : null}
            </form>
        </main>
    ) : (
        <div id='dashboard'>
            <div
                id='sidebar'
                className={mobileSidebarOpened ? 'opened' : undefined}
            >
                <Link to='/'>
                    <h1>
                        <Logo />
                    </h1>
                </Link>
                <nav>
                    <Link
                        to='/dashboard/home'
                        className={
                            location.pathname
                                .toLowerCase()
                                .startsWith('/dashboard/home')
                                ? 'active'
                                : undefined
                        }
                    >
                        <FontAwesomeIcon icon={faHome} />
                        Home
                    </Link>
                    <Link
                        to='/dashboard/news'
                        className={
                            location.pathname
                                .toLowerCase()
                                .startsWith('/dashboard/news')
                                ? 'active'
                                : undefined
                        }
                        onClick={() => setOpen(false)}
                    >
                        <NewsIcon />
                        News
                    </Link>
                    <Link
                        to='/dashboard/portfolio'
                        className={
                            location.pathname
                                .toLowerCase()
                                .startsWith('/dashboard/portfolio')
                                ? 'active'
                                : undefined
                        }
                        onClick={() => setOpen(false)}
                    >
                        <PortfolioIcon />
                        Portfolio
                    </Link>
                    <button type='button' onClick={() => auth.signOut()}>
                        <LogoutIcon />
                        Logout
                    </button>
                </nav>
            </div>
            <div id='header-placeholder' />
            <header>
                <button
                    type='button'
                    id='sidebar-toggler'
                    onClick={() => setOpen(!mobileSidebarOpened)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <Link to='/'>
                    <HomeIcon />
                </Link>
            </header>
            <Switch>
                <Route path='/dashboard/home' component={Home} />
                <Route path='/dashboard/news' component={News} />
                <Route path='/dashboard/portfolio' component={Portfolio} />
                <Route>
                    <Redirect to='/dashboard/home' />
                </Route>
            </Switch>
        </div>
    );
}
