import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import firebase from 'firebase/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faEdit,
    faHourglassHalf,
    faPlusCircle,
    faTrashAlt,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RootState } from '../../../Redux/store';
import './news.less';

export default function News(): JSX.Element {
    const { data } = useSelector((state: RootState) => state.fetchNews);
    const [editTarget, setEditTarget] = useState('');
    const [editData, setEditData] = useState({
        timestamp: Date.now(),
        img: '' as string | null,
        title: '',
        content: '',
    });
    const [uploading, setUploading] = useState(false);
    if (!data) {
        return <main />;
    }

    const sortedData = Object.entries(data).sort(
        ([, { timestamp: a }], [, { timestamp: b }]) => b - a
    );

    return editTarget ? (
        <main id='news-editor' className='editor'>
            <h1>News</h1>
            <label htmlFor='topic'>
                Topic
                <input
                    onChange={evt => {
                        editData.title = evt.target.value;
                        setEditData({ ...editData });
                    }}
                    id='topic'
                    type='textbox'
                    defaultValue={editData.title}
                />
            </label>
            <label htmlFor='timestamp'>
                Timestamp
                <input
                    onChange={evt => {
                        const [y, m, d] = evt.target.value
                            .split('-')
                            .map(str => parseInt(str, 10));
                        editData.timestamp = new Date(y, m - 1, d).valueOf();
                        setEditData({ ...editData });
                    }}
                    id='timestamp'
                    type='date'
                    defaultValue={
                        new Date(editData.timestamp).toISOString().split('T')[0]
                    }
                />
            </label>
            <label htmlFor='image'>
                Image
                {editData.img ? (
                    <figure>
                        <img src={editData.img} alt={editData.title} />
                    </figure>
                ) : null}
                <input
                    type='file'
                    accept='image/*'
                    onChange={(evt): void => {
                        if (evt.target.files) {
                            const reader = new FileReader();
                            const file = evt.target.files[0];
                            if (file) {
                                reader.readAsDataURL(file);
                                reader.onloadend = (): void => {
                                    // eslint-disable-next-line no-param-reassign
                                    editData.img = reader.result as string;
                                    setEditData({ ...editData });
                                };
                            }
                        }
                    }}
                />
            </label>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Content</label>
            <CKEditor
                editor={ClassicEditor}
                data={editData.content}
                config={{
                    toolbar: [
                        'undo',
                        'redo',
                        '|',
                        'bold',
                        'italic',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'link',
                    ],
                }}
                onChange={(
                    _: unknown,
                    editor: {
                        getData: () => string;
                    }
                ): void => {
                    editData.content = editor.getData();
                    setEditData({ ...editData });
                }}
            />
            <button
                type='button'
                aria-label='return'
                onClick={() => {
                    setEditData({
                        timestamp: 0,
                        img: '',
                        title: '',
                        content: '',
                    });
                    setEditTarget('');
                }}
            >
                Go Back <FontAwesomeIcon icon={faUndo} />
            </button>
            <button
                disabled={uploading}
                type='button'
                aria-label='save'
                onClick={async () => {
                    setUploading(true);
                    const newData = { ...editData };
                    let imageString = '';
                    if (
                        newData.img &&
                        /^data:image\/([a-zA-Z]*);base64,/.test(newData.img)
                    ) {
                        imageString = newData.img;
                        newData.img = null;
                    }
                    const database = firebase.database();
                    const storage = firebase.storage();
                    const ref =
                        editTarget === 'temp'
                            ? database.ref('/news').push()
                            : database.ref('/news').child(editTarget);

                    await ref.set(newData);
                    if (imageString) {
                        const storageRef = storage
                            .ref('News/')
                            .child(ref.key as string);
                        await storageRef.putString(imageString, 'data_url');
                        const imageUrl = await storageRef.getDownloadURL();
                        await ref.child('img').set(imageUrl);
                    }
                    setUploading(false);
                    setEditData({
                        timestamp: 0,
                        img: '',
                        title: '',
                        content: '',
                    });
                    setEditTarget('');
                }}
            >
                {uploading ? (
                    <>
                        Saving... <FontAwesomeIcon icon={faHourglassHalf} />
                    </>
                ) : (
                    <>
                        Save <FontAwesomeIcon icon={faCheck} />
                    </>
                )}
            </button>
        </main>
    ) : (
        <main id='news'>
            <div id='header'>
                <h1>News</h1>
                <h2>{Object.keys(data || {}).length} total articles</h2>
                <button type='button' onClick={() => setEditTarget('temp')}>
                    Add Page <FontAwesomeIcon icon={faPlusCircle} />
                </button>
            </div>
            <div className='wrapper'>
                <table className='desktop'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Topic</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map(([key, article]) => (
                            <tr key={key}>
                                <td>
                                    {new Date(article.timestamp).toDateString()}
                                </td>
                                <td>{article.title}</td>
                                <td>
                                    <button
                                        type='button'
                                        aria-label='edit'
                                        onClick={() => {
                                            setEditTarget(key);
                                            setEditData({
                                                timestamp: article.timestamp,
                                                img: article.img,
                                                title: article.title,
                                                content: article.content,
                                            });
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                                <td>
                                    <button
                                        type='button'
                                        aria-label='delete'
                                        onClick={async () => {
                                            await firebase
                                                .database()
                                                .ref('/news')
                                                .child(key)
                                                .set(null);
                                            await firebase
                                                .storage()
                                                .ref('News/')
                                                .child(key)
                                                .delete();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className='mobile'>
                    <thead>
                        <tr>
                            <th colSpan={3}>Topic</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map(([key, article]) => (
                            <React.Fragment key={key}>
                                <tr>
                                    <td colSpan={3}>{article.title}</td>
                                </tr>
                                <tr>
                                    <td>
                                        {new Date(
                                            article.timestamp
                                        ).toDateString()}
                                    </td>
                                    <td>
                                        <button
                                            type='button'
                                            aria-label='edit'
                                            onClick={() => {
                                                setEditTarget(key);
                                                setEditData({
                                                    timestamp:
                                                        article.timestamp,
                                                    img: article.img,
                                                    title: article.title,
                                                    content: article.content,
                                                });
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            type='button'
                                            aria-label='delete'
                                            onClick={async () => {
                                                await firebase
                                                    .database()
                                                    .ref('/news')
                                                    .child(key)
                                                    .set(null);
                                                await firebase
                                                    .storage()
                                                    .ref('News/')
                                                    .child(key)
                                                    .delete();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </button>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
}
