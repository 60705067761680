import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import fetchPortfolio from './fetchPortfolio';
import fetchNews from './fetchNews';
import fetchHome from './fetchHome';

const rootReducer = combineReducers({
    fetchPortfolio,
    fetchNews,
    fetchHome,
});
export type RootState = ReturnType<typeof rootReducer>;
export const store = createStore(
    rootReducer,
    process.env.NODE_ENV === 'development'
        ? composeWithDevTools(applyMiddleware())
        : undefined
);
