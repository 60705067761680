/* eslint-disable react/jsx-indent */
import {
    faCheck,
    faHourglassHalf,
    faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Locale } from '../../../Redux/base-types';
import { BlockContent, Home } from '../../../Redux/fetchHome';
import { RootState } from '../../../Redux/store';
import './home.less';

export default function HomeDashboard(): JSX.Element {
    const { data } = useSelector((state: RootState) => state.fetchHome);
    const [editTarget, setEditTarget] = useState<keyof Home>();
    const [introData, setIntroData] = useState<
        Pick<Home, 'background' | 'introduction'>
    >();
    const [approachData, setApproachData] = useState<Home['approach']>();
    const [investmentData, setInvestmentData] = useState<Home['investment']>();
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (data) {
            setIntroData({
                background: data.background,
                introduction: data.introduction,
            });
            setApproachData(data.approach);
            setInvestmentData(data.investment);
        }
    }, [data]);

    return editTarget ? (
        <main id='home-editor' className='editor'>
            <h1>{editTarget.toUpperCase()}</h1>
            {editTarget === 'approach' || editTarget === 'investment'
                ? Object.entries(
                      (editTarget === 'approach'
                          ? approachData
                          : investmentData) ||
                          ({} as { [key: number]: BlockContent })
                  ).map(([key, value]) =>
                      (['title', 'content'] as ['title', 'content']).map(
                          property =>
                              (['en', 'zh'] as ['en', 'zh']).map(locale => (
                                  <label
                                      key={`${key}${property}${locale}`}
                                      htmlFor={`${key}${property}${locale}`}
                                  >
                                      {
                                          // eslint-disable-next-line no-nested-ternary
                                          property === 'title'
                                              ? locale === 'en'
                                                  ? 'Title'
                                                  : '標題'
                                              : locale === 'en'
                                              ? 'Content'
                                              : '內容'
                                      }{' '}
                                      {key}
                                      <input
                                          type='textbox'
                                          onChange={evt => {
                                              const newData =
                                                  (editTarget === 'approach'
                                                      ? approachData
                                                      : investmentData) ||
                                                  ({} as {
                                                      [
                                                          key: number
                                                      ]: BlockContent;
                                                  });
                                              newData[Number(key)][property][
                                                  locale
                                              ] = evt.target.value;
                                              // eslint-disable-next-line no-unused-expressions
                                              editTarget === 'approach'
                                                  ? setApproachData(
                                                        newData as Home['approach']
                                                    )
                                                  : setInvestmentData(
                                                        newData as Home['investment']
                                                    );
                                          }}
                                          defaultValue={value[property][locale]}
                                      />
                                  </label>
                              ))
                      )
                  )
                : (['en', 'zh'] as ['en', 'zh']).map(locale => (
                      <label key={`${locale}`} htmlFor={`${locale}`}>
                          {locale === 'en' ? 'Content' : '內容'}
                          {editTarget === 'introduction' ? (
                              <input
                                  type='textbox'
                                  onChange={evt => {
                                      const newData = {
                                          ...introData,
                                      } as typeof introData;
                                      if (newData) {
                                          newData.introduction =
                                              newData?.introduction ||
                                              ({ zh: '', en: '' } as Locale);
                                          newData.background =
                                              newData?.background ||
                                              ({ zh: '', en: '' } as Locale);
                                          newData.introduction[locale] =
                                              evt.target.value;
                                          setIntroData(newData);
                                      }
                                  }}
                                  defaultValue={introData?.introduction[locale]}
                              />
                          ) : (
                              <CKEditor
                                  editor={ClassicEditor}
                                  data={introData?.background[locale]}
                                  config={{
                                      toolbar: [
                                          'undo',
                                          'redo',
                                          '|',
                                          'bold',
                                          'italic',
                                          'numberedList',
                                          'bulletedList',
                                          '|',
                                          'link',
                                      ],
                                  }}
                                  onChange={(
                                      _: unknown,
                                      editor: {
                                          getData: () => string;
                                      }
                                  ): void => {
                                      const newData = {
                                          ...introData,
                                      } as typeof introData;
                                      if (newData) {
                                          newData.introduction =
                                              newData?.introduction ||
                                              ({ zh: '', en: '' } as Locale);
                                          newData.background =
                                              newData?.background ||
                                              ({ zh: '', en: '' } as Locale);
                                          newData.background[
                                              locale
                                          ] = editor.getData();
                                          setIntroData(newData);
                                      }
                                  }}
                              />
                          )}
                      </label>
                  ))}
            <button
                type='button'
                aria-label='return'
                onClick={() => {
                    if (data) {
                        setIntroData({
                            background: data.background,
                            introduction: data.introduction,
                        });
                        setApproachData(data.approach);
                        setInvestmentData(data.investment);
                    }
                    setEditTarget(undefined);
                }}
            >
                Go Back <FontAwesomeIcon icon={faUndo} />
            </button>
            <button
                disabled={uploading}
                type='button'
                aria-label='save'
                onClick={async () => {
                    setUploading(true);
                    const newData =
                        // eslint-disable-next-line no-nested-ternary
                        editTarget === 'approach'
                            ? approachData
                            : editTarget === 'investment'
                            ? investmentData
                            : introData?.[editTarget];
                    const database = firebase.database();
                    const ref = database.ref('/home').child(editTarget);
                    await ref.set(newData);
                    setUploading(false);
                    if (data) {
                        setIntroData({
                            background: data.background,
                            introduction: data.introduction,
                        });
                        setApproachData(data.approach);
                        setInvestmentData(data.investment);
                    }
                    setEditTarget(undefined);
                }}
            >
                {uploading ? (
                    <>
                        Saving... <FontAwesomeIcon icon={faHourglassHalf} />
                    </>
                ) : (
                    <>
                        Save <FontAwesomeIcon icon={faCheck} />
                    </>
                )}
            </button>
        </main>
    ) : (
        <main id='home'>
            <div id='header'>
                <h1>Home</h1>
            </div>
            <div className='wrapper'>
                {([
                    'introduction',
                    'background',
                    'approach',
                    'investment',
                ] as (keyof Home)[]).map(type => (
                    <React.Fragment key={type}>
                        <h2>{type.toUpperCase()}</h2>
                        <table>
                            <thead>
                                <th>Edit</th>
                                {type === 'approach' ||
                                type === 'investment' ? (
                                    <>
                                        <th>Title</th>
                                        <th>Description</th>
                                    </>
                                ) : (
                                    <th>Content</th>
                                )}
                            </thead>
                            <tbody>
                                {type === 'approach' ||
                                type === 'investment' ? (
                                    (Object.entries(data?.[type] || {}) as [
                                        'approach' | 'investment',
                                        BlockContent
                                    ][]).map(([key, value], i, arr) => (
                                        <tr key={key}>
                                            {i === 0 ? (
                                                <td rowSpan={arr.length}>
                                                    <button
                                                        type='button'
                                                        className='edit'
                                                        onClick={() => {
                                                            setEditTarget(type);
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                            ) : null}
                                            <td>
                                                {value.title.en}
                                                <br />
                                                {value.title.zh}
                                            </td>
                                            <td>
                                                {value.content.en}
                                                <br />
                                                {value.content.zh}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>
                                            <button
                                                type='button'
                                                className='edit'
                                                onClick={() => {
                                                    setEditTarget(type);
                                                }}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                        {type === 'background' ? (
                                            <td
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: `${data?.background.en}</br>${data?.background.zh}`,
                                                }}
                                            />
                                        ) : (
                                            <td>
                                                {data?.introduction.en} :{' '}
                                                {data?.introduction.zh}
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </React.Fragment>
                ))}
            </div>
        </main>
    );
}
