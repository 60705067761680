import React from 'react';
import { Parallax } from 'react-parallax';
import { getLanguage, useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import BackgroundImage from './bg.png';
import pic1 from './pic1.png';
import pic2 from './pic2.png';
import investBg from '../Banner Section/Invest.png';
import PartnerBg from '../Banner Section/Partner.png';
import ExpandBg from '../Banner Section/Expand.png';
import { ReactComponent as StageSvg } from './Stage_ICON.svg';
import { ReactComponent as StratSvg } from './Strategies_ICON.svg';
import { ReactComponent as InvestSvg } from './ROI_ICON.svg';
import { ReactComponent as GeoSvg } from './G_ICON.svg';
import './parallax.less';
import { RootState } from '../../Redux/store';

export default function Main(): JSX.Element {
    const t = useTranslation();
    const locale = getLanguage() as 'en' | 'zh';
    const { data } = useSelector((state: RootState) => state.fetchHome);
    return (
        <section id='parallax'>
            <Parallax
                bgImage={BackgroundImage}
                strength={5000}
                contentClassName='wrapper'
            >
                <div className='parallelogram' id='p1' />
                <div className='parallelogram' id='p2' />
                <div id='card1'>
                    <h2>Capital TimeX</h2>
                    <div className='wrapper'>
                        <img src={pic1} alt='conference' />
                        <span>{data?.introduction[locale]}</span>
                    </div>
                </div>
                <div id='card2'>
                    <h2>{t('Main.Homepage.Who Are We')}</h2>
                    <img src={pic2} alt='urban' />
                    <div className='overlay'>
                        <h2>{t('Main.Homepage.Who Are We')}</h2>
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: data?.background[locale] || '<p></p>',
                            }}
                        />
                    </div>
                    <div className='parallelogram' id='p5' />
                </div>
                <div id='card3' className='approach'>
                    <div className='parallelogram' id='p3' />
                    <img src={investBg} alt={data?.approach[1].title[locale]} />
                    <div className='overlay' />
                    <div className='wrapper'>
                        <h2>{t('Main.Homepage.Our Approach')}</h2>
                        <Parallax
                            renderLayer={percent => (
                                <>
                                    <h3
                                        style={{
                                            bottom: `${
                                                (1 -
                                                    Math.min(
                                                        1,
                                                        percent * 1.75
                                                    )) *
                                                    -300 +
                                                (window.innerWidth > 1280
                                                    ? 129
                                                    : 47)
                                            }px`,
                                        }}
                                    >
                                        1
                                    </h3>
                                    <div className='parallelogram counter' />
                                </>
                            )}
                        />
                        <h4>{data?.approach[1].title[locale]}</h4>
                        <p>{data?.approach[1].content[locale]}</p>
                    </div>
                </div>
                <div id='card4' className='approach'>
                    <img
                        src={PartnerBg}
                        alt={data?.approach[2].title[locale]}
                    />
                    <div className='wrapper'>
                        <Parallax
                            renderLayer={percent => (
                                <>
                                    <h3
                                        style={{
                                            bottom: `${
                                                (1 -
                                                    Math.min(
                                                        1,
                                                        percent * 1.75
                                                    )) *
                                                    -300 +
                                                (window.innerWidth > 1280
                                                    ? 129
                                                    : 47)
                                            }px`,
                                        }}
                                    >
                                        2
                                    </h3>
                                    <div className='parallelogram counter' />
                                </>
                            )}
                        />
                        <h4>{data?.approach[2].title[locale]}</h4>
                        <p>{data?.approach[2].content[locale]}</p>
                    </div>
                </div>
                <div id='card5' className='approach'>
                    <div className='parallelogram' id='p4' />
                    <img src={ExpandBg} alt={data?.approach[3].title[locale]} />
                    <div className='wrapper'>
                        <Parallax
                            renderLayer={percent => (
                                <>
                                    <h3
                                        style={{
                                            bottom: `${
                                                (1 -
                                                    Math.min(
                                                        1,
                                                        percent * 1.75
                                                    )) *
                                                    -300 +
                                                (window.innerWidth > 1280
                                                    ? 129
                                                    : 47)
                                            }px`,
                                        }}
                                    >
                                        3
                                    </h3>
                                    <div className='parallelogram counter' />
                                </>
                            )}
                        />
                        <h4>{data?.approach[3].title[locale]}</h4>
                        <p>{data?.approach[3].content[locale]}</p>
                    </div>
                </div>
                <div id='card6'>
                    <h2>{t('Main.Homepage.Investment Overview')}</h2>
                    <div className='flex-box'>
                        <div className='wrapper'>
                            <StageSvg />
                            <div>
                                <h4>{data?.investment[1].title[locale]}</h4>
                                <p>{data?.investment[1].content[locale]}</p>
                            </div>
                        </div>
                        <div className='wrapper'>
                            <StratSvg />
                            <div>
                                <h4>{data?.investment[2].title[locale]}</h4>
                                <p>{data?.investment[2].content[locale]}</p>
                            </div>
                        </div>
                        <div className='wrapper'>
                            <InvestSvg />
                            <div>
                                <h4>{data?.investment[3].title[locale]}</h4>
                                <p>{data?.investment[3].content[locale]}</p>
                            </div>
                        </div>
                        <div className='wrapper'>
                            <GeoSvg />
                            <div>
                                <h4>{data?.investment[4].title[locale]}</h4>
                                <p>{data?.investment[4].content[locale]}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
        </section>
    );
}
