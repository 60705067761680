import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-multi-lang';
import './footer.less';

export default function Footer(): JSX.Element {
    const t = useTranslation();
    return (
        <footer>
            <div className='wrapper'>
                © 2021 Capital TimeX. {t('Footer.All Rights Reserved')} |{' '}
                <Link to='/privacy'>{t('Footer.Privacy Statement')}</Link>
            </div>
        </footer>
    );
}
