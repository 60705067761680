import React from 'react';
import { Parallax } from 'react-parallax';
import { useTranslation, getLanguage } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { ReactComponent as X } from '../Banner Section/X.svg';
import { RootState } from '../../Redux/store';
import BackgroundImage from './bg.png';
import './portfolio.less';

export default function Portfolio(): JSX.Element {
    const t = useTranslation();
    const locale = getLanguage() as 'en' | 'zh';
    const { data } = useSelector((state: RootState) => state.fetchPortfolio);

    return (
        <main id='portfolio'>
            <section id='banner'>
                <X />
                <div className='wrapper'>
                    <h1>{t('Main.Portfolio.Portfolio')}</h1>
                    <div>
                        <p>{t('Main.Portfolio.PortfolioDesc')}</p>
                    </div>
                </div>
            </section>
            {data ? (
                <Parallax
                    bgImage={BackgroundImage}
                    strength={2000}
                    contentClassName='wrapper'
                >
                    <div className='parallelogram' id='p1' />
                    <div className='parallelogram' id='p2' />
                    <div id='card1'>
                        <h2>{t('Main.Portfolio.Private Equity')}</h2>
                        {Object.entries(data['private-equity'])
                            .sort(([, a], [, b]) => a.order - b.order)
                            .map(([key, company]) => (
                                <div key={key}>
                                    {/* <div
                                        className='company-image'
                                        style={{
                                            backgroundImage: `url(${company.img})`,
                                        }}
                                    /> */}
                                    <figure>
                                        <img
                                            src={company.img}
                                            alt={company.title[locale]}
                                        />
                                    </figure>
                                    <h4>
                                        <a href={company.url}>
                                            {company.title[locale]}
                                        </a>
                                    </h4>
                                    <p>{company.content[locale]}</p>
                                </div>
                            ))}
                    </div>
                    <h2>{data.section2.title[locale]}</h2>
                    <div
                        id='card2'
                        className='full-width'
                        style={{
                            backgroundImage: `url(${data.section2.img})`,
                        }}
                    >
                        <div className='parallelogram' id='p3' />
                        <div className='wrapper'>
                            <div
                                className='text'
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: data.section2.content[locale],
                                }}
                            />
                        </div>
                    </div>
                    <h2>{data.section3.title[locale]}</h2>
                    <div
                        id='card3'
                        className='full-width'
                        style={{
                            backgroundImage: `url(${data.section3.img})`,
                        }}
                    >
                        <div className='wrapper'>
                            <div
                                className='text'
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: data.section3.content[locale],
                                }}
                            />
                        </div>
                    </div>
                </Parallax>
            ) : null}
        </main>
    );
}
