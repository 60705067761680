import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { setTranslations, setDefaultLanguage } from 'react-multi-lang';
import firebase from 'firebase/app';
import firebaseApp from './initFirebase';
import Header from './Components/Header/header';
import Footer from './Components/Footer/footer';
import Homepage from './Components/Homepage/homepage';
import Portfolio from './Components/Portfolio/portfolio';
import News from './Components/News/news';
import Article from './Components/News/article';
import Contact from './Components/Contact/contact';
import Dashboard from './Components/Dashboard/dashboard';
import en from './Locale/en.json';
import zh from './Locale/zh.json';
import './App.less';

export default function App(): JSX.Element {
    const dispatch = useDispatch();
    setTranslations({ en, zh });
    setDefaultLanguage(
        navigator.languages.find(lan => lan.startsWith('zh')) ? 'zh' : 'en'
    );

    useEffect(() => {
        const database = firebaseApp.database();
        function fetchAndDispatch(path: string, success: string, fail: string) {
            const dispatchSuccess = (
                snapshot: firebase.database.DataSnapshot
            ): { type: string; payload: unknown } =>
                dispatch({
                    type: success,
                    payload: snapshot.val(),
                });
            database
                .ref(path)
                .once('value')
                .then(dispatchSuccess)
                .catch(err =>
                    dispatch({
                        type: fail,
                        payload: err,
                    })
                );
            database.ref(path).on('value', dispatchSuccess);
        }
        fetchAndDispatch('/home', 'FETCH_HOME_SUCCESS', 'FETCH_HOME_FAIL');
        fetchAndDispatch('/portfolio', 'FETCH_PORT_SUCCESS', 'FETCH_PORT_FAIL');
        fetchAndDispatch('/news', 'FETCH_NEWS_SUCCESS', 'FETCH_NEWS_FAIL');
    }, []);

    return (
        <>
            <BrowserRouter>
                <Switch>
                    <Route path='/dashboard' component={Dashboard} />
                    <Route>
                        <Header />
                        <Switch>
                            <Route
                                exact
                                path='/portfolio'
                                component={Portfolio}
                            />
                            <Route exact path='/news' component={News} />
                            <Route path='/news/:title' component={Article} />
                            <Route exact path='/contact' component={Contact} />
                            <Route component={Homepage} />
                        </Switch>
                        <Footer />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    );
}
