import { SuccessAction, FailureAction, Locale, FetchState } from './base-types';

const SUCCESS = 'FETCH_PORT_SUCCESS';
const FAIL = 'FETCH_PORT_FAIL';

export interface Content {
    content: Locale;
    img: string;
    title: Locale;
    url: string;
    order: number;
}

export interface Portfolio {
    'private-equity': {
        [key: string]: Content;
    };
    section2: Pick<Content, 'content' | 'img' | 'title'>;
    section3: Pick<Content, 'content' | 'img' | 'title'>;
}

type Action =
    | SuccessAction<typeof SUCCESS, Portfolio>
    | FailureAction<typeof FAIL>;

const initialState: FetchState<Portfolio> = {
    data: undefined,
    error: undefined,
};

export default function reducer(
    state = initialState,
    action: Action
): FetchState<Portfolio> {
    switch (action.type) {
        case SUCCESS:
            return {
                data: action.payload,
                error: undefined,
            };
        case FAIL:
            return {
                data: undefined,
                error: action.payload,
            };
        default:
            return state;
    }
}
