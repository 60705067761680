import React, { useState } from 'react';
import { getLanguage } from 'react-multi-lang';
import { useSelector } from 'react-redux';
import { ReactComponent as X } from './X.svg';
import defaultBg from './default.png';
import investBg from './Invest.png';
import PartnerBg from './Partner.png';
import ExpandBg from './Expand.png';
import './banner.less';
import { RootState } from '../../Redux/store';

export default function Banner(): JSX.Element {
    const locale = getLanguage() as 'en' | 'zh';
    const states = {
        default: defaultBg,
        1: investBg,
        2: PartnerBg,
        3: ExpandBg,
    };
    const { data } = useSelector((state: RootState) => state.fetchHome);
    const [state, setState] = useState<keyof typeof states>('default');

    return (
        <section id='banner'>
            <div id='left' style={{ backgroundImage: `url(${states[state]})` }}>
                <X />
                {['default', 1, 2, 3].map(item => (
                    <div
                        className={`wrapper ${
                            state === item ? 'show' : ''
                        } ${state}`}
                        key={item}
                    >
                        {state === 'default' ? (
                            <>
                                <h1>{data?.approach[1].title[locale]}</h1>
                                <h1>{data?.approach[2].title[locale]}</h1>
                                <h1>{data?.approach[3].title[locale]}</h1>
                            </>
                        ) : (
                            <>
                                <h1>{data?.approach[state].title[locale]}</h1>
                                <div>
                                    <i>
                                        {data?.approach[state].content[locale]}
                                    </i>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <div id='right'>
                <ol>
                    {([1, 2, 3] as [1, 2, 3]).map(item => (
                        <li
                            key={item}
                            onMouseOut={() => setState('default')}
                            onBlur={() => setState('default')}
                            onMouseEnter={() => setState(item)}
                        >
                            <span className='counter'>{item}</span>
                            <span className='name'>
                                {data?.approach[item].title[locale]}
                            </span>
                        </li>
                    ))}
                </ol>
            </div>
        </section>
    );
}
